import React from 'react'
import { useStaticQuery, graphql} from 'gatsby'
import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'


const OrderForm = ({location}) => {

  
  const data = useStaticQuery(
    graphql`
      query {
        pdfOrderForm: contentfulAsset(
          contentful_id: { eq: "62zkqXnlp7ZfS0Dq7mJAX1" }
        ) {
          file {
            url
          }
        }
        excelOrderForm: contentfulAsset(
          contentful_id: { eq: "4V1hvJb3ZuaKhzGmA70anJ" }
        ) {
          file {
            url
          }
        }
      }
    `
  )

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Order Form" />

      <div className="flex-row mt-3">
        <div className="d-flex justify-content-center">
          <h1 className="text-primary text-reset">Order Forms</h1>
        </div>
      </div>
      <div className="flex-row my-3">
        <div className="d-flex justify-content-center ">
          <div className="home-page-section">
            <div className="card-deck">
              <div className="card ">
                <div className="card-body">
                  <h2 className="card-title">Excel</h2>
                  <a href={data.excelOrderForm.file.url}>Download</a>
                </div>
              </div>
              <div className="card ">
                <div className="card-body">
                  <h2 className="card-title">PDF</h2>
                  <a href={data.pdfOrderForm.file.url}>Download</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )}

  export default OrderForm